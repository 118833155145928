import { type FC } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Privacy: FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#F4F1ED] via-[#F4F1ED] to-royal-purple/[0.08] relative">
      <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-[0.02]" />
      <main className="min-h-screen p-4 relative">
        <div className="w-full max-w-4xl mx-auto">
          <div className="command-panel rounded-xl p-6 backdrop-blur-xl bg-white/95 shadow-luxury border-[1.5px] border-royal-purple/10 hover:shadow-[0_16px_60px_rgba(74,21,75,0.15),0_6px_15px_rgba(74,21,75,0.12)] transition-all duration-500">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="space-y-6"
            >
              <div className="space-y-2">
                <h1 className="text-2xl font-serif text-pearl">Privacy Policy</h1>
                <p className="text-pearl/60 text-xs">Last updated: November 2024</p>
              </div>

              <div className="space-y-4 text-pearl/80 text-sm">
                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">1. Information Collection</h2>
                  <p className="leading-relaxed">
                    Crown Intel collects strategic business information to deliver premium market positioning insights. 
                    This includes business email addresses, brand positioning data, and market segment analysis. Our 
                    data collection processes are designed to gather the intelligence necessary for elite market transformation.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">2. Information Usage</h2>
                  <p className="leading-relaxed">
                    Your information enables us to craft bespoke strategic frameworks and premium positioning systems. 
                    We analyze market data to identify opportunities for premium segment capture and develop 
                    customized execution playbooks for your brand's transformation.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">3. Data Protection</h2>
                  <p className="leading-relaxed">
                    We employ advanced security measures to protect your strategic information. Our systems are 
                    designed with multiple layers of protection to ensure your market intelligence and positioning 
                    strategies remain confidential and secure.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">4. Information Sharing</h2>
                  <p className="leading-relaxed">
                    Your strategic information is treated with the utmost confidentiality. We do not share your 
                    proprietary data with third parties unless explicitly required for delivering our premium 
                    positioning services or required by law.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">5. Data Retention</h2>
                  <p className="leading-relaxed">
                    We maintain your strategic information for the duration necessary to deliver our premium 
                    positioning services and comply with legal obligations. This ensures continuous strategic 
                    support while protecting your market interests.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">6. Your Rights</h2>
                  <p className="leading-relaxed">
                    As a valued strategic partner, you have the right to access, correct, or request deletion of 
                    your information. We ensure transparent control over your data while maintaining the integrity 
                    of our strategic services.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">7. Updates to Policy</h2>
                  <p className="leading-relaxed">
                    We may update this policy to reflect enhancements in our strategic services or comply with 
                    regulatory requirements. Partners will be notified of significant changes to ensure continued 
                    strategic alignment.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">8. Contact Information</h2>
                  <p className="leading-relaxed">
                    For privacy-related inquiries or to exercise your data rights, please contact our Strategic 
                    Privacy Office at privacy@crownintel.com
                  </p>
                </section>
              </div>

              <div className="pt-4 border-t border-power-gold/10">
                <Link 
                  to="/" 
                  className="text-power-gold hover:text-rose-gold transition-colors duration-300 text-sm font-mono"
                >
                  ← Return to Application Portal
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </main>
    </div>
  );
};