import { type FC } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const MainContent: FC = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const getNextQuarter = () => {
    const now = new Date();
    const currentQuarter = Math.floor(now.getMonth() / 3) + 1;
    const currentYear = now.getFullYear();
    const nextQuarter = currentQuarter === 4 ? 1 : currentQuarter + 1;
    const nextYear = currentQuarter === 4 ? currentYear + 1 : currentYear;
    return `Q${nextQuarter} ${nextYear}`;
  };

  const paragraphs = [
    {
      text: "For visionary e-commerce brand owners seeking unmatched market dominance. Selected brands receive our proprietary Sacred Market Codex™, meticulously crafted and personalized for your unique market position. Each strategic blueprint includes custom psychological pricing matrices, premium brand archetypes, strategic narrative systems, and unconscious buying triggers - all tailored to your specific business.",
      delay: 0
    },
    {
      text: "New positions open quarterly, with a fully refundable $400 application deposit required to ensure serious inquiries only. This deposit is returned if not selected or applied to future strategic services upon acceptance. Our battle-tested frameworks and psychological principles are adapted specifically for your brand, giving you exclusive tools to command premium pricing while competitors remain trapped in mass-market price wars.",
      delay: 0.2
    },
    {
      text: `Secure your priority consideration for ${getNextQuarter()} by joining the private waitlist now. With a strict limit of 12 positions per quarter to maintain exclusivity and ensure genuine competitive advantage, successful applicants gain privileged access to proprietary methodologies that remain inaccessible to competitors. Your early action secures the opportunity to dominate your market's premium segment before others can react.`,
      delay: 0.4
    }
  ];

  return (
    <motion.div 
      ref={ref} 
      className="space-y-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      {paragraphs.map((p, index) => (
        <motion.p
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6, delay: p.delay }}
          className="text-sm sm:text-base leading-relaxed text-pearl/80 font-light tracking-wide"
        >
          {p.text}
        </motion.p>
      ))}
    </motion.div>
  );
};