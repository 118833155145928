import { type FC } from 'react';
import { Header } from '../components/Header';
import { WaitlistForm } from '../components/WaitlistForm';
import { MainContent } from '../components/MainContent';
import { MarketIntel } from '../components/MarketIntel';

export const Home: FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#F4F1ED] via-[#F4F1ED] to-royal-purple/[0.08] relative">
      <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-[0.02]" />
      <main className="min-h-screen flex items-center justify-center py-12 px-4 relative">
        <div className="w-full max-w-5xl mx-auto">
          <div className="command-panel rounded-xl p-6 backdrop-blur-xl bg-white/95 shadow-luxury border-[1.5px] border-royal-purple/10 hover:shadow-[0_16px_60px_rgba(74,21,75,0.15),0_6px_15px_rgba(74,21,75,0.12)] transition-all duration-500">
            <div className="grid lg:grid-cols-12 gap-6">
              <div className="lg:col-span-7 space-y-6">
                <Header />
                <MainContent />
                <WaitlistForm />
              </div>
              <div className="lg:col-span-5">
                <MarketIntel />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};