import { type FC } from 'react';
import { motion } from 'framer-motion';

export const MarketIntel: FC = () => {
  const getNextQuarter = () => {
    const now = new Date();
    const currentQuarter = Math.floor(now.getMonth() / 3) + 1;
    const currentYear = now.getFullYear();
    const nextQuarter = currentQuarter === 4 ? 1 : currentQuarter + 1;
    const nextYear = currentQuarter === 4 ? currentYear + 1 : currentYear;
    return `Q${nextQuarter} ${nextYear}`;
  };

  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6 }}
      className="relative w-full h-full"
    >
      <div className="h-full flex flex-col text-center">
        <div className="space-y-3 flex-1">
          {/* Application Fee */}
          <div className="bg-white backdrop-blur-sm rounded-lg p-4 border-[2px] border-power-gold/30 shadow-lg hover:border-power-gold/40 transition-all duration-300">
            <p className="text-xs font-mono text-command-black/70 uppercase tracking-wider">Application Deposit</p>
            <div className="flex justify-center items-baseline gap-2 mt-3">
              <p className="text-3xl font-mono text-power-gold font-feature-zero-open font-bold">$400</p>
            </div>
            <p className="text-xs font-mono text-command-black/50 mt-2">Fully Refundable If Not Selected</p>
          </div>

          {/* Current Status */}
          <div className="bg-white backdrop-blur-sm rounded-lg p-4 border-[2px] border-rose-gold/30 shadow-lg hover:border-rose-gold/40 transition-all duration-300">
            <p className="text-xs font-mono text-command-black/70 uppercase tracking-wider">Current Status</p>
            <div className="flex justify-center items-baseline gap-2 mt-3">
              <p className="text-3xl font-mono text-rose-gold font-bold">Waitlist</p>
            </div>
            <p className="text-xs font-mono text-command-black/50 mt-2">Next Review: {getNextQuarter()}</p>
          </div>

          {/* What You Get */}
          <div className="bg-white backdrop-blur-sm rounded-lg p-4 border-[2px] border-power-gold/20 shadow-lg hover:border-power-gold/30 transition-all duration-300">
            <p className="text-xs font-mono text-command-black/70 uppercase tracking-wider">Strategic Access</p>
            <div className="space-y-2 mt-3">
              <div className="flex items-center justify-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-power-gold"></div>
                <p className="text-sm font-mono text-command-black/90">Premium Positioning Framework</p>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-power-gold"></div>
                <p className="text-sm font-mono text-command-black/90">Bespoke Execution Playbooks</p>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-power-gold"></div>
                <p className="text-sm font-mono text-command-black/90">Psychological Trigger System</p>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-power-gold"></div>
                <p className="text-sm font-mono text-command-black/90">Team Psychology Profiling</p>
              </div>
            </div>
          </div>
        </div>

        {/* Logo */}
        <div className="bg-white backdrop-blur-sm rounded-lg p-4 mt-3 flex-1 flex flex-col justify-center border-[2px] border-power-gold/20 shadow-lg hover:border-power-gold/30 transition-all duration-300">
          <div className="flex items-center justify-center">
            <motion.svg 
              width="80" 
              height="80" 
              viewBox="0 0 100 100" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              initial={{ opacity: 0, rotate: -90 }}
              animate={{ opacity: 1, rotate: 0 }}
              transition={{ duration: 1.2, delay: 0.6 }}
            >
              {/* SVG content remains the same */}
              <g clipPath="url(#clip0)">
                <circle cx="50" cy="50" r="48" stroke="url(#logoGradient)" strokeWidth="2"/>
                <path d="M25 62.5L22.5 37.5L34.5 50L50 37.5L65.5 50L77.5 37.5L75 62.5L50 70.8333L25 62.5Z" stroke="url(#logoGradient)" strokeWidth="2" fill="none"/>
                <path d="M30 60L27.5 42.5L37.5 52.5L50 42.5L62.5 52.5L72.5 42.5L70 60L50 66.5L30 60Z" fill="url(#logoGradient)" opacity="0.3"/>
                <circle cx="22.5" cy="37.5" r="2.5" fill="url(#logoGradient)" />
                <circle cx="50" cy="37.5" r="2.5" fill="url(#logoGradient)" />
                <circle cx="34.5" cy="50" r="2.5" fill="url(#logoGradient)" />
                <circle cx="65.5" cy="50" r="2.5" fill="url(#logoGradient)" />
                <circle cx="77.5" cy="37.5" r="2.5" fill="url(#logoGradient)" />
                <line x1="25" y1="62.5" x2="75" y2="62.5" stroke="url(#logoGradient)" strokeWidth="2" />
              </g>
              <defs>
                <linearGradient id="logoGradient" x1="0" y1="0" x2="100" y2="100" gradientUnits="userSpaceOnUse">
                  <stop offset="0%" stopColor="#B8860B" />
                  <stop offset="100%" stopColor="#DBA17A" />
                </linearGradient>
                <clipPath id="clip0">
                  <rect width="100" height="100" fill="white"/>
                </clipPath>
              </defs>
            </motion.svg>
          </div>
          <div className="mt-3">
            <p className="text-xs font-mono text-command-black/50 uppercase tracking-wider">Crown Intel</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};