import { type FC } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Terms: FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#F4F1ED] via-[#F4F1ED] to-royal-purple/[0.08] relative">
      <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-[0.02]" />
      <main className="min-h-screen p-4 relative">
        <div className="w-full max-w-4xl mx-auto">
          <div className="command-panel rounded-xl p-6 backdrop-blur-xl bg-white/95 shadow-luxury border-[1.5px] border-royal-purple/10 hover:shadow-[0_16px_60px_rgba(74,21,75,0.15),0_6px_15px_rgba(74,21,75,0.12)] transition-all duration-500">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="space-y-6"
            >
              <div className="space-y-2">
                <h1 className="text-2xl font-serif text-pearl">Terms and Conditions</h1>
                <p className="text-pearl/60 text-xs">Last updated: November 2024</p>
              </div>

              <div className="space-y-4 text-pearl/80 text-sm">
                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">1. Introduction</h2>
                  <p className="leading-relaxed">
                    Welcome to Crown Intel ("Company", "we", "our", "us"), your gateway to elite brand transformation. 
                    These Terms and Conditions govern your access to our exclusive strategic services and proprietary methodologies. 
                    By accessing our services, you join an elite circle of forward-thinking brands committed to market dominance.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">2. Services</h2>
                  <p className="leading-relaxed">
                    Crown Intel delivers transformative luxury brand architecture and strategic positioning services. 
                    Our comprehensive suite includes advanced market intelligence, proprietary strategic frameworks, 
                    and premium positioning systems meticulously crafted for elite beauty and luxury brands seeking 
                    market leadership.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">3. Application Process</h2>
                  <p className="leading-relaxed">
                    3.1. Your $400 application deposit is fully refundable if not selected, ensuring a risk-free opportunity 
                    to be considered for our exclusive program.<br />
                    3.2. Selection is based on our proprietary market position analysis, designed to identify brands with 
                    exceptional growth potential.<br />
                    3.3. We maintain selective admission standards to ensure the highest quality of strategic collaboration.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">4. Intellectual Property</h2>
                  <p className="leading-relaxed">
                    Our strategic methodologies, frameworks, and systems represent decades of refined expertise in luxury 
                    market dynamics. These proprietary assets are protected by intellectual property laws, ensuring their 
                    exclusive value for our selected partners.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">5. Confidentiality</h2>
                  <p className="leading-relaxed">
                    The strategic insights and methodologies we share with our partners are carefully guarded assets. 
                    This commitment to confidentiality ensures our partners maintain their competitive advantage in 
                    the luxury market.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">6. Limitation of Liability</h2>
                  <p className="leading-relaxed">
                    While Crown Intel provides expert strategic guidance based on proven methodologies, market success 
                    depends on various factors. We focus on delivering actionable intelligence while our partners retain 
                    full control over their business decisions.
                  </p>
                </section>

                <section className="space-y-2">
                  <h2 className="text-lg font-serif text-pearl">7. Contact</h2>
                  <p className="leading-relaxed">
                    For inquiries about these terms or to begin your brand's transformation journey, 
                    contact us at legal@crownintel.com
                  </p>
                </section>
              </div>

              <div className="pt-4 border-t border-power-gold/10">
                <Link 
                  to="/" 
                  className="text-power-gold hover:text-rose-gold transition-colors duration-300 text-sm font-mono"
                >
                  ← Return to Application Portal
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </main>
    </div>
  );
};